<template>
  <div class="wrap-content">
    <div class="header">
      <div class="return">
        <div class="return-icon" @click="handleBack">
          <Icon type="ios-arrow-back" />
        </div>
        <span>新建</span>
        <span>/&nbsp;核心数据</span>
        <span>/&nbsp;填报方式</span>
      </div>
      <div class="button">
        <CommonHeader />
      </div>
    </div>
    <div class="list">
      <h2>选择填报方式</h2>
      <FillType />
    </div>
    <Footer />
  </div>
</template>

<script setup>
import Footer from "@/components/footer.vue";
import FillType from "./components/comfillType.vue";
import { useRouter } from "vue-router";
import CommonHeader from "@/components/commonHeader.vue"
let router = useRouter();
const handleBack = () => {
  router.back();
};
</script>

<style lang="scss" scoped>
.wrap-content {
  padding: 0 40px;
  background: #fff;
  height: 100%;
}
.list {
  height: calc(100% - 105px);
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  > h2 {
    text-align: center;
    margin-bottom: 30px;
    font-family: PingFangSC-Regular;
    font-size: 36px;
    font-weight: normal;
    font-stretch: normal;
    color: #111111;
    margin-top: -50px;
  }
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  // padding: 15px 0;
  height: 70px;
  .return {
    display: flex;
    align-items: center;
    .return-icon {
      width: 24px;
      height: 24px;
      border: solid 2px #111111;
      border-radius: 6px;
      font-weight: bold;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 8px;
      cursor: pointer;
      i {
        font-size: 18px;
        color: #000;
      }
    }
    span {
      font-family: PingFangSC-Medium;
      font-size: 26px;
      font-weight: normal;
      font-stretch: normal;
      line-height: 33px;
      color: #111111;
    }
    span:last-child {
      color: #cccccc;
    }
  }
}
.button {
  display: flex;
  align-items: center;
  > div {
    margin-left: 20px;
  }

  .icon {
    img {
      display: block;
      width: 20px;
      height: 18px;
    }
  }
}
</style>