<template>
  <Modal
    v-model="modal"
    sticky
    scrollable
    :mask-closable="true"
    width="60%"
    class="custom-modal"
    @on-cancel="modal = false"
  >
    <template #header>
      <p class="title">批量添加</p>
      <div class="modal-close" @click="modal = false">关闭 <span>ESC</span></div>
    </template>
    <div class="flex-center-between margin-bottom-10">
      <div class="btn btn-default" @click="downTemplate">
        <Icon type="md-download" />点击下载模板文件
      </div>
      <p class="orange">请上传xlsx文件，大小在60M以内</p>
    </div>
    <Upload
      multiple
      type="drag"
      :action="action"
      ref="upload"
      :max-size="61440"
      accept="xlsx"
      :format="format"
      :headers="headers"
      :show-upload-list="false"
      :on-success="handleSuccess"
      :on-error="handleError"
      :on-format-error="handleFormatError"
      :on-exceeded-size="handleMaxSize"
      :on-progress ="handleProgress"
    >
    <div class="upload-content">
        <img
          src="../../../assets/img/点击上传-默认.png"
          alt=""
          class="upload-logo"
        />
        <h2>点击上传</h2>
        <p>或者拖拽本地文件到此区域</p>
      </div>
    </Upload>

    <template #footer> </template>
  </Modal>
</template>

<script setup>
import { Modal, Message } from "view-ui-plus";
import { reactive, ref, onMounted, inject } from "vue";
import { useRouter } from "vue-router";
import DownFiles from "@/utils/dowFile";
import bus from "@/utils/bus";
// import bus from "@/utils/bus";
let modal = ref(false);
// let action = process.env.VUE_APP_URL + `/content/import/general`;
let action = process.env.VUE_APP_URL + `/content/import`;
let format =['xlsx']
let headers = ref({
  Authorization: "",
});

let data = reactive({
  file: "",
});
let axios = inject("axios");
const modify = () => {
  modal.value = true;
};
//将方法暴露给父组件使用
defineExpose({ modify });

let router = useRouter();
//上传成功
const handleSuccess = (res, file) => {
  // console.log(res)
   bus.emit("fileInfo",file)
    modal.value = false;
    bus.emit('openValidate')
  // if (res.code == 200) {
  //   // Message.success("上传成功");
   
  // } else {
  //   Message.error({
  //     background: true,
  //     content: res.message,
  //     duration: 5,
  //   });
  // }
};
//上传失败
const handleError = (res, file) => {
  // Modal.error({
  //   title: "提醒",
  //   content: res.data.message,
  // });
  bus.emit('errorInfo',file);
  modal.value = false;
  bus.emit("openValidate");
};
//上传格式错误
const handleFormatError = (file) => {
  Message.warning({
    background: true,
    content: "文件" + file.name + "格式错误,请选择xlsx格式文件",
    duration: 5,
  });
};
//超过上传大小
const handleMaxSize = (file) => {
  Message.warning({
    background: true,
    content: "文件" + file.name + " 太大，不要超过20M.",
    duration: 5,
  });
};

const handleProgress =(event, file, fileList)=>{
//  console.log(event, file, fileList);
 let status = file.status;
}
//下载模板
const downTemplate = () => {
  let url = `/content/template?type=2`;
  axios.get(url, { responseType: "blob" }).then((res) => {
    // console.log(res);
    if (res.status == 200) {
      const blob = res.data;
      // 获取文件名
      const content = res.headers["content-disposition"];
      const fileName = content && content.split(";")[1].split("filename=")[1];
      DownFiles(blob, fileName);
    }
  });
};
//获取用户信息
const getUserInfo = () => {
  if (localStorage.getItem("User")) {
    let user = JSON.parse(localStorage.getItem("User"));
    if (user != null && user != undefined) {
      headers.value.Authorization = "Bearer " + user.auth_token;
    }
  }
};

onMounted(() => {
  getUserInfo();
});
</script>

<style lang="scss" scoped>
//弹框样式
.title {
  color: #3f3f3f;
  font-size: 22px;
  // margin-bottom: 20px;
}
.orange {
  color: #ffad59;
  line-height: 30px;
}
.btn {
  background: #3c55a9;
  font-size: 14px;
  color: #fff;
  margin-top: 20px;
}
.upload-content {
  padding: 110px 0;
  .upload-logo {
    display: inline-block;
    width: 28px;
    height: 35px;
  }
  h2 {
    font-size: 20px;
    font-weight: normal;
    font-stretch: normal;
    color: #111111;
    margin: 25px 0;
    line-height: 36px;
  }
  p {
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    color: #555555;
    line-height: 30px;
  }
}
</style>