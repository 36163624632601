<template>
  <div class="box">
    <div class="way" @click="handleRouter('coreData')">
      <h2>常规填报</h2>
      <p>在填报页面创建单条数据目录</p>
      <div class="logo">
        <img src="../../../assets/img/Regularreporting.png" alt="" />
      </div>
    </div>
    <!-- <div class="way" @click="handleBatch">
      <h2>批量添加</h2>
      <p>使用Excel填报模板创建多条数据目录</p>
      <div class="logo">
        <img src="../../../assets/img/批量添加.png" alt="" />
      </div>
    </div> -->
    <div class="way" @click="handleImport">
      <h2>从数据源导入</h2>
      <p>通过互操作授权从科学数据中心选取导入多条数据目录</p>
      <div class="logo">
        <img src="../../../assets/img/Datasourceimport.png" alt="" />
      </div>
    </div>
  </div>
  <!-- 批量添加 -->
  <batch ref="batchRef" />
  <sourceImport ref="sourceRef" />
  <!-- webscoke验证 -->
  <validateFormat ref="refValidate"  />
</template>

<script setup>
import { useRouter } from "vue-router";
import { ref, onMounted } from "vue";
import batch from "./combatch.vue";
import bus from "@/utils/bus";
import sourceImport from "./comsourceImport.vue";
import validateFormat from "./validateFormat.vue";
let modal = ref(false);

// import bus from '@/utils/bus'
let router = useRouter();
const handleRouter = (name) => {
  router.push({ name });
};

//控制批量弹框弹出
const batchRef = ref();
const handleBatch = () => {
  batchRef.value.modify();
};
//控制数据源弹框弹出
const sourceRef = ref();
const handleImport = () => {
  sourceRef.value.modify();
};

//控制数据源弹框弹出
const refValidate = ref();
const handleValidate = () => {
  refValidate.value.modify();
};

onMounted(() => {
  // bus.on("openValidate", () => {
  //   console.log(33)
  //   modal.value = true;
  // });
});
</script>

<style lang="scss" scoped>
.box {
  display: flex;
  justify-content: center;
  align-items: center;
}
.way {
  background-color: #f6f6f6;
  border-radius: 6px;
  margin-right: 10px;
  padding: 0 20px;
  width: 250px;
  height: 320px;
  position: relative;
  border-radius: 20px;
  border: solid 3px #f6f6f6;
  box-sizing: border-box;
  h2 {
    color: #555555;
    font-size: 24px;
    margin-bottom: 30px;
    text-align: center;
    margin-top: 40px;
    font-weight: lighter;
  }
  p {
    color: #555555;
    font-size: 14px;
    text-align: center;
    font-family: PingFangSC-Regular;
    font-weight: normal;
    font-stretch: normal;
    line-height: 30px;
    text-align: center;
  }
  .logo {
    position: absolute;
    left: 50%;
    bottom: -3px;
    transform: translate(-50%, 0);
    width: 90px;
    height: 100px;
    img {
      display: block;
      width: 30px;
      height: 30px;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }
}
.way:hover {
  h2 {
    color: #111111;
    font-weight: bold;
  }
}
.way:nth-child(1) {
  .logo {
    background: #688aeb;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
  }
}
.way:nth-child(1):hover {
  border-radius: 20px;
  border: solid 3px #688aea;
  cursor: pointer;
  box-sizing: border-box;
  .logo {
    height: 130px;
    img {
      animation: foudeIn 50ms linear;
      top: 30%;
    }
  }
}
.way:nth-child(2) {
  .logo {
    background: #ff7757;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
  }
}
.way:nth-child(2):hover {
  border: solid 3px #ff7757;
  cursor: pointer;
  box-sizing: border-box;
  .logo {
    height: 130px;
    img {
      animation: foudeIn 50ms linear;
      top: 30%;
    }
  }
}
.way:last-child {
  margin-right: 0;
  .logo {
    background: #8f76f6;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
  }
}
.way:last-child:hover {
  border: solid 3px #8e76f6;
  cursor: pointer;
  box-sizing: border-box;
  .logo {
    height: 130px;
    img {
      animation: foudeIn 50ms linear;
      top: 30%;
    }
  }
}
@keyframes foudeIn {
  from {
    top: 50%;
  }
  to {
    top: 30%;
  }
}
</style>